import React from 'react'
import Lottie from 'react-lottie';
import animationData from '../assets/animation.json';

const Animation = () => {
  const defaultOptions = {
    loop: true, // Set whether the animation should loop
    autoplay: true, // Set whether the animation should play automatically
    animationData: animationData, // The JSON animation data you imported
    // rendererSettings: {
    //   preserveAspectRatio: 'xMidYMid slice', // You can adjust the rendering settings
    // },
  };

  const animationContainerStyle = {
    width: '100%',
    maxWidth: '300px', // Adjust the width as needed
    position: "absolute",
    left: "0",
    right: "0",
    margin: "0 auto"
  };

  return (
    <div style={animationContainerStyle} className="lottie-container">
      <Lottie options={defaultOptions} />
    </div>
  )
}

export default Animation;

