import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import Header from '../components/Header'
import playerimg from "../assets/images/player.png"
import { Emailwhite, Country, Phone } from '../assets/icons/icon'
import { NavLink, useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import AxiosRequest from '../AxiosRequest'
import placeholderImg from "../utils/placeholderImg";
import OverlayLoader from '../components/OverlayLoader';


const initValue = { data: [], loading: false }
const MyProfile = () => {
  const [flag, setFlag] = useState(false);
  const user = useMemo(() => JSON.parse(localStorage.getItem("user")), [flag])
  const [profile, setProfile] = useState(initValue)
  const reff = useRef()
  const navigate = useNavigate()

  const userToken = localStorage.getItem("userToken");

  useEffect(() => {
    if (!user.isVerified) navigate('/update-profile')
    // if(userToken && !user.isVerified) navigate('/update-profile')
  }, [user, userToken])

  const getProfile = async () => {
    try {
      reff.current = true
      setProfile(prev => ({ ...prev, loading: true }))
      const { data } = await AxiosRequest.get(`users/profile/web`);
      const _profleData = { ...data?.data?.data, quizStatistics: data?.data?.quizStatistics }
      setProfile(prev => ({
        data: _profleData,
        loading: false
      }))
      localStorage.setItem("user", JSON.stringify(_profleData));
      setFlag(!flag)
    }
    catch (err) {
      console.log(err)
      toast.error(err?.response?.data?.message || err?.message || "Something went wrong");
      setProfile(initValue)
    }
  }

  useEffect(() => {
    if (!reff?.current) getProfile()
  }, [])
  return (
    <>
      <div>
        <Header />
        <Container className='myprofile-page'>
          <h4 className='fontwhite24 py-3 mb-3'>Profile</h4>
          <div className='myprofile-box'>
            <Row>
              <Col lg={6} md={12} className='mb-5 mb-lg-0'>
                <h5 className='fontwhite30-bold'>Personal Details</h5>
                <div className='d-flex my-4'>
                  <img src={user?.photo || placeholderImg(user.name)} alt='' className='me-3' style={{ height: "100px", width: "100px", borderRadius: "50px" }} />
                  <div >
                    <h5 className='fontwhite24-light'>{user.name}</h5>
                    <Button as={NavLink} to={'/update-profile'} className='next-btn w-100 mb-5' variant=''>
                      Edit profile
                    </Button>
                  </div>
                </div>
                {user?.email && (
                  <div className='mb-3 d-flex align-items-center'>
                    <Emailwhite /><span className='fontwhite20-light ms-4'>{user?.email}</span>
                  </div>
                )}
                {/* {user?.email && ( */}
                {user?.mobileNumber ? 
                <div className='mb-3 d-flex align-items-center'>
                  <Phone /><span className='fontwhite20-light ms-4'>{user?.countryCode} {user?.mobileNumber}</span>
                </div> : null}
                {/* )} */}
                {user?.city && (
                  <div>
                    <Country /><span className='fontwhite20-light ms-4'>{user?.city}</span>
                  </div>
                )}
              </Col>
              <Col lg={6} md={12}>
                <h5 className='fontwhite30-bold mb-3'>Contest Details</h5>
                <div className='content-box'>
                  <div className='content'>
                    <h5 className='fontwhite30 mb-0'>Total Quiz</h5>
                    <h6 className='fontskyblue30 mb-0'>{user?.quizStatistics?.totalRounds}</h6>
                  </div>
                  <div className='content'>
                    <h5 className='fontwhite30 mb-0'>Attempted Quiz</h5>
                    <h6 className='fontskyblue30 mb-0'>{user?.quizStatistics?.totalAttempted}</h6>
                  </div>
                  <div className='content'>
                    <h5 className='fontwhite30 mb-0'>Passed</h5>
                    <h6 className='fontskyblue30 mb-0'>{user?.quizStatistics?.totalPass}</h6>
                  </div>
                  <div className='content'>
                    <h5 className='fontwhite30 mb-0'>Failed</h5>
                    <h6 className='fontskyblue30 mb-0'>{user?.quizStatistics?.totalFail}</h6>
                  </div>
                </div>
              </Col>
            </Row>

          </div>
        </Container>
      </div>
      {profile.loading ? <OverlayLoader /> : null}
    </>
  )
}

export default MyProfile