import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header";
import HomepageCard from "../components/HomepageCard";
import { Button, Container, Row, Spinner } from "react-bootstrap";
import { InfoIcon, Leaderboard } from "../assets/icons/icon";
import toast from "react-hot-toast";
import AxiosRequest from "../AxiosRequest";
import { NavLink } from "react-router-dom";
import OverlayLoader from "../components/OverlayLoader";
import NoData from "../components/NoData";

const initValue = { data: [], total: 0, loading: false };
const Home = () => {
	const reff = useRef();
	const [personaQuizList, setPersonaQuizList] = useState(initValue);

	const getPersonaQuizList = async () => {
		try {
			reff.current = true;
			setPersonaQuizList((prev) => ({ ...prev, loading: true }));
			const { data } = await AxiosRequest.get(`quiz/web`);
			const dataWithPracticeQuiz =
				data.data.data.length > 0
					? data.data.data.filter(
							(quiz) => quiz?.practiceQuiz !== true
					  )
					: [];
			setPersonaQuizList((prev) => ({
				data: dataWithPracticeQuiz,
				total: data.data.totalCount,
				loading: false,
			}));
		} catch (err) {
			console.log(err);
			toast.error(
				err?.response?.data?.message ||
					err?.message ||
					"Something went wrong"
			);
			setPersonaQuizList(initValue);
		} finally {
		}
	};

	useEffect(() => {
		if (!reff?.current) getPersonaQuizList();
	}, []);

	return (
		<>
			<Header />
			<Container className="homepage">
				<Row className="g-4 my-3">
					<div className="d-flex justify-content-end">
						<NavLink
							to={"/info"}
							className="orange-btn text-decoration-none"
						>
							<span className="me-2">
								<InfoIcon />
							</span>
							info
						</NavLink>
						{/*<NavLink
							to={"/leaderboard"}
							className="pink-btn ms-2 text-decoration-none"
						>
							<span className="me-2">
								<Leaderboard />
							</span>
							Leaderboard
	</NavLink> */}
					</div>
					{personaQuizList.loading ? (
						<div className="d-flex align-items-center justify-content-center py-5">
							<Spinner
								variant="light"
								style={{ height: "50px", width: "50px" }}
							/>
						</div>
					) : personaQuizList.data.length === 0 ? (
						<div className="d-flex align-items-center justify-content-center py-5">
							<NoData className="opacity-75" />
						</div>
					) : (
						personaQuizList.data.map((q, i) => (
							<HomepageCard key={i} data={q} />
						))
					)}
				</Row>
			</Container>
			{/* {personaQuizList.loading ? <OverlayLoader /> : null} */}
		</>
	);
};

export default Home;
