// import React, { useEffect, useRef, useState } from "react";
// import logo from "../assets/images/logoNew.png";
// import TitleSponser from "../assets/images/title-sponsor-logo-v1.png";
// import {
// 	Button,
// 	Col,
// 	Container,
// 	Form,
// 	InputGroup,
// 	Row,
// 	Spinner,
// } from "react-bootstrap";
// import Avatarimg from "../assets/images/Profile.png";
// import Emailimg from "../assets/images/Email.png";
// import Phoneimg from "../assets/images/Phone.png";
// import RulesForQuiz from "../assets/rules-for-quiz.pdf";
// import Cityimg from "../assets/images/City.png";
// import Instituteimg from "../assets/images/Institute.png";
// import { toast } from "react-hot-toast";
// import * as Yup from "yup";
// import { useFormik } from "formik";
// import { parsePhoneNumber } from "react-phone-number-input";
// import AxiosRequest from "../AxiosRequest";
// import { useNavigate } from "react-router-dom";
// import OverlayLoader from "../components/OverlayLoader";
// import uploadImg from "../utils/uploadImg";
// import PhoneInput, {
// 	isPossiblePhoneNumber,
// 	isValidPhoneNumber,
// } from "react-phone-number-input";

// const validationSchema = Yup.object({
// 	fName: Yup.string()
// 		.trim("")
// 		.notOneOf([""], "Empty space is not allowed")
// 		.min(2, "Too Short!")
// 		.max(25, "Too Long!")
// 		.required("This field is required.")
// 		.nullable(),
// 	lName: Yup.string()
// 		.trim("")
// 		.notOneOf([""], "Empty space is not allowed")
// 		.min(2, "Too Short!")
// 		.max(25, "Too Long!")
// 		// .required("This field is required.")
// 		.nullable(),
// 	email: Yup.string()
// 		.trim("")
// 		.notOneOf([""], "Empty space is not allowed")
// 		// .required("This field is required.")
// 		.email("Email must be in valid format")
// 		.nullable(),
// 	gender: Yup.string().required("This field is required.").nullable(),
// 	city: Yup.string()
// 		.trim("")
// 		.notOneOf([""], "Empty space is not allowed")
// 		.min(2, "Too Short!")
// 		.max(25, "Too Long!")
// 		// .required("This field is required.")
// 		.nullable(),
// 	mobileNumber: Yup.string().test(
// 		"valid-phone",
// 		"Invalid phone number",
// 		(value) => {
// 			return (
// 				!value ||
// 				(isValidPhoneNumber(value || "") &&
// 					isPossiblePhoneNumber(value || ""))
// 			);
// 		}
// 	) .required("This field is required."),
// 	// participantName: Yup.string()
// 	// 	.trim("")
// 	// 	.notOneOf([""], "Empty space is not allowed")
// 	// 	.min(2, "Too Short!")
// 	// 	.max(25, "Too Long!")
// 	// 	.required("This field is required.")
// 	// 	.nullable(),
// 	schoolName: Yup.string()
// 		.trim("")
// 		.notOneOf([""], "Empty space is not allowed")
// 		.min(2, "Too Short!")
// 		.max(25, "Too Long!")
// 		.required("This field is required.")
// 		.nullable(),
// 	schoolAddress: Yup.string()
// 		.trim("")
// 		.notOneOf([""], "Empty space is not allowed")
// 		.min(2, "Too Short!")
// 		.max(25, "Too Long!")
// 		.required("This field is required.")
// 		.nullable(),
// 	schoolAddress: Yup.string()
// 		.trim("")
// 		.notOneOf([""], "Empty space is not allowed")
// 		.min(2, "Too Short!")
// 		.max(25, "Too Long!")
// 		.required("This field is required.")
// 		.nullable(),
// 	parentName: Yup.string()
// 		.trim("")
// 		.notOneOf([""], "Empty space is not allowed")
// 		.min(2, "Too Short!")
// 		.max(25, "Too Long!")
// 		.required("This field is required.")
// 		.nullable(),
// 	parentContactNumber: Yup.string().required("This field is required.").test(
// 		"valid-phone",
// 		"Invalid phone number",
// 		(value) => {
// 			return (
// 				!value ||
// 				(isValidPhoneNumber(value || "") &&
// 					isPossiblePhoneNumber(value || ""))
// 			);
// 		}
// 	),
// 	parentEmail: Yup.string()
// 		.trim("")
// 		.notOneOf([""], "Empty space is not allowed")
// 		.required("This field is required.")
// 		.email("Email must be in valid format")
// 		.nullable(),
// });

// const initValue = { data: [], loading: false };
// const UpdateProfile = () => {
// 	const reff = useRef();
// 	const navigate = useNavigate();
// 	const [profile, setProfile] = useState(initValue);
// 	const [displayImage, setDisplayImage] = useState(null);
// 	const [isAgreeTerms, setIsAgreeTerms] = useState(false);

// 	const formik = useFormik({
// 		initialValues: {
// 			// name: "",
// 			photo: "",
// 			fName: "",
// 			lName: "",
// 			gender: "",
// 			city: "",
// 			// ------
// 			email: "",
// 			mobileNumber: "",
// 			// participantName: "",
// 			schoolName: "",
// 			schoolAddress: "",
// 			parentName: "",
// 			parentContactNumber: "",
// 			parentEmail: "",
// 		},
// 		validationSchema,
// 		onSubmit: async (values, { setSubmitting, resetForm }) => {
// 			try {
// 				const payload = {};
// 				const _name = `${values?.fName.trim()} ${
// 					values?.lName ? values?.lName.trim() : ""
// 				}`;
// 				if (values.photo && values.photo instanceof File) {
// 					const _img = await uploadImg({ imageFile: values.photo });
// 					payload["photo"] = _img;
// 				} else {
// 					payload["photo"] = values.photo || "";
// 				}

// 				payload["name"] = _name.trim();
// 				payload["gender"] = values.gender;
// 				payload["city"] = values.city ? values.city.trim() : "";

// 				if (values.mobileNumber) {
// 					const parsedNumber = parsePhoneNumber(values.mobileNumber);
// 					if (parsedNumber) {
// 						payload[
// 							"countryCode"
// 						] = `+${parsedNumber.countryCallingCode}`;
// 						payload["mobileNumber"] = parsedNumber.nationalNumber;
// 					}
// 				}

// 				if (values?.email) {
// 					payload["email"] = values?.email
// 						? values?.email.trim()
// 						: "";
// 				}

// 				// if(values?.participantName) {
// 				// 	payload["participantName"] = values?.participantName ? values?.participantName?.trim() : ""
// 				// }

// 				if(values?.schoolName) {
// 					payload["schoolName"] = values?.schoolName ? values?.schoolName?.trim() : ""
// 				}

// 				if(values?.schoolAddress) {
// 					payload["schoolAddress"] = values?.schoolAddress ? values?.schoolAddress?.trim() : ""
// 				}

// 				if(values?.parentName) {
// 					payload["parentName"] = values?.parentName ? values?.parentName?.trim() : ""
// 				}

// 				if(values?.parentContactNumber) {
// 					payload["parentContactNumber"] = values?.parentContactNumber ? values?.parentContactNumber?.trim() : ""
// 				}

// 				if(values?.parentEmail) {
// 					payload["parentEmail"] = values?.parentEmail ? values?.parentEmail?.trim() : ""
// 				}

// 				const { data } = await AxiosRequest.post(`users/register-user-ccd`,{...payload,});

// 				toast.success(data.message);
// 				resetForm();
// 				await getProfile();
// 				navigate(`/`);
// 			} catch (err) {
// 				console.log(err);
// 				toast.error(
// 					err?.response?.data?.message ||
// 						err?.message ||
// 						"Something went wrong"
// 				);
// 			}
// 		},
// 	});

// 	const getProfile = async () => {
// 		try {
// 			reff.current = true;
// 			setProfile((prev) => ({ ...prev, loading: true }));
// 			const { data } = await AxiosRequest.get(`users/profile/web`);
// 			const _profleData = {
// 				...data?.data?.data,
// 				quizStatistics: data?.data?.quizStatistics,
// 			};
// 			setProfile((prev) => ({
// 				data: _profleData,
// 				loading: false,
// 			}));
// 			localStorage.setItem("user", JSON.stringify(_profleData));
// 		} catch (err) {
// 			console.log(err);
// 			toast.error(
// 				err?.response?.data?.message ||
// 					err?.message ||
// 					"Something went wrong"
// 			);
// 			setProfile(initValue);
// 		}
// 	};

// 	useEffect(() => {
// 		if (!reff?.current) getProfile();
// 	}, []);

// 	const setProfileData = async () => {
// 		await formik.setFieldValue("photo", profile?.data?.photo || "");
// 		await formik.setFieldValue("fName", (profile?.data?.name && profile?.data?.name.split(" ")[0]) || "");
// 		await formik.setFieldValue("lName", (profile?.data?.name && profile?.data?.name.split(" ")[1]) || "");

// 		if (profile?.data?.countryCode && profile?.data?.mobileNumber) {
// 			await formik.setFieldValue("mobileNumber", `${profile?.data?.countryCode}${profile?.data?.mobileNumber}`);
// 		} else {
// 			await formik.setFieldValue("mobileNumber", "");
// 		}
// 		// --- optional ---
// 		await formik.setFieldValue("email", profile?.data?.email || "");
// 		await formik.setFieldValue("email", profile?.data?.email || "");
// 		await formik.setFieldValue("gender", profile?.data?.gender || "");
// 		await formik.setFieldValue("city", profile?.data?.city || "");

// 		// --- Extra Fields ---
// 		// await formik.setFieldValue("participantName", profile?.data?.participantName || "");
// 		await formik.setFieldValue("schoolName", profile?.data?.schoolName || "");
// 		await formik.setFieldValue("schoolAddress", profile?.data?.schoolAddress || "");
// 		await formik.setFieldValue("parentName", profile?.data?.parentName || "");
// 		await formik.setFieldValue("parentContactNumber", profile?.data?.parentContactNumber || "");
// 		await formik.setFieldValue("parentEmail", profile?.data?.parentEmail || "");

// 	};

// 	useEffect(() => {
// 		if (!profile.loading && profile.data) setProfileData();
// 	}, [profile]);

// 	const handleSelectImage = (event) => {
// 		const _file = event.target.files && event.target.files[0];
// 		formik.setFieldValue("photo", _file);
// 		setDisplayImage(window.URL.createObjectURL(_file));
// 	};

// 	return (
// 		<>
// 			<div>
// 				<Container>
// 					<div className=" login-page profile-page">
// 						<div className="login-logo header1">
// 							<img src={logo} alt="" className="logo logo1" />
// 							<span className="vertical-line"></span>
// 							<img
// 								src={TitleSponser}
// 								alt=""
// 								className="logo logo2"
// 							/>
// 						</div>
// 						<div className="profile-box login-box">
// 							<h4 className="fontwhite24 text-center my-5">
// 								Complete Profile
// 							</h4>
// 							<div
// 								id="content custom-uploader"
// 								className=" d-flex justify-content-center"
// 							>
// 								<label
// 									htmlFor="profileImg"
// 									className="uploader"
// 								>
// 									<input
// 										hidden
// 										id="profileImg"
// 										type="file"
// 										accept=".png, .jpg, .jpeg"
// 										onChange={handleSelectImage}
// 									/>
// 									{formik.values.photo ? (
// 										<img
// 											src={
// 												displayImage ||
// 												formik.values.photo
// 											}
// 											alt="profile"
// 										/>
// 									) : (
// 										<svg
// 											xmlns="http://www.w3.org/2000/svg"
// 											width="30"
// 											height="23"
// 											viewBox="0 0 30 23"
// 											fill="none"
// 										>
// 											<path
// 												d="M16.0726 22.7273V16.2338H20.3586L15.0011 9.74026L9.64355 16.2338H13.9296V22.7273H8.57204V22.6732C8.39203 22.684 8.22059 22.7273 8.03629 22.7273C5.90493 22.7273 3.86087 21.8721 2.35377 20.3499C0.846678 18.8277 0 16.7631 0 14.6104C0 10.4459 3.11808 7.05195 7.11908 6.58658C7.46987 4.7344 8.4487 3.06357 9.88722 1.86147C11.3257 0.659371 13.134 0.00114289 15.0011 0C16.8684 0.00105555 18.677 0.659205 20.1158 1.86128C21.5547 3.06335 22.534 4.73422 22.8852 6.58658C26.8862 7.05195 30 10.4459 30 14.6104C30 16.7631 29.1533 18.8277 27.6462 20.3499C26.1391 21.8721 24.0951 22.7273 21.9637 22.7273C21.7837 22.7273 21.6101 22.684 21.428 22.6732V22.7273H16.0726Z"
// 												fill="#42EFEB"
// 											/>
// 										</svg>
// 									)}
// 								</label>
// 							</div>
// 							<div className="right-section">
// 								<Form className="login-form w-100">
// 									<Row className="p-md-5 p-3 g-4">
// 										<Col xl={6} md={12}>
// 											<Form.Group className="">
// 												<InputGroup>
// 													<InputGroup.Text id="basic-addon2">
// 														<img
// 															src={Avatarimg}
// 															alt=""
// 															className=""
// 														/>
// 													</InputGroup.Text>
// 													<Form.Control
// 														placeholder="First Name"
// 														type="text"
// 														value={formik.values.fName}
// 														onChange={formik.handleChange}
// 														onBlur={formik.handleBlur}
// 														name="fName"
// 													/>
// 												</InputGroup>
// 												{formik.touched.fName &&
// 												formik.errors.fName ? (
// 													<Form.Text className="text-danger">
// 														{formik.errors.fName}
// 													</Form.Text>
// 												) : null}
// 											</Form.Group>
// 										</Col>
// 										<Col xl={6} md={12}>
// 											<Form.Group className="">
// 												<InputGroup>
// 													<InputGroup.Text id="basic-addon2">
// 														<img
// 															src={Avatarimg}
// 															alt=""
// 															className=""
// 														/>
// 													</InputGroup.Text>
// 													<Form.Control
// 														placeholder="Last Name"
// 														type="text"
// 														value={formik.values.lName}
// 														onChange={formik.handleChange}
// 														onBlur={formik.handleBlur}
// 														name="lName"
// 													/>
// 												</InputGroup>
// 												{formik.touched.lName &&
// 												formik.errors.lName ? (
// 													<Form.Text className="text-danger">
// 														{formik.errors.lName}
// 													</Form.Text>
// 												) : null}
// 											</Form.Group>
// 										</Col>
// 										<Col xl={12}>
// 											<Form.Group className="">
// 												<div className="d-flex align-items-center flex-wrap">
// 													<span className="fontwhite14 me-2">
// 														Gender :
// 													</span>
// 													<label
// 														htmlFor="male"
// 														class="size-2 radioButton"
// 													>
// 														<input
// 															type="radio"
// 															id="male"
// 															name="gender"
// 															className="radioButtonInput"
// 															checked={formik.values.gender === "male"}
// 															onChange={() =>formik.setFieldValue("gender","male")}
// 															onBlur={formik.handleBlur}
// 														/>
// 														Male
// 													</label>
// 													<label
// 														htmlFor="female"
// 														class="size-2 radioButton"
// 													>
// 														<input
// 															type="radio"
// 															id="female"
// 															name="gender"
// 															className="radioButtonInput"
// 															checked={formik.values.gender === "female"}
// 															onChange={() =>formik.setFieldValue("gender","female")}
// 															onBlur={formik.handleBlur}
// 														/>
// 														Female
// 													</label>
// 												</div>
// 												{formik.touched.gender && formik.errors.gender ? (
// 													<Form.Text className="text-danger">
// 														{formik.errors.gender}
// 													</Form.Text>
// 												) : null}
// 											</Form.Group>
// 										</Col>
// 										<Col xl={6} md={12}>
// 											{/* {profile?.data?.emailLogin ? ( */}
// 												<Form.Group className="mb-0 mb-lg-4">
// 													<InputGroup>
// 														<PhoneInput
// 															placeholder="Enter phone number"
// 															value={formik.values.mobileNumber}
// 															onChange={(phone) =>
// 																formik.setFieldValue("mobileNumber",phone)
// 															}
// 															onBlur={formik.handleBlur}
// 															name="mobileNumber"
// 															className="customPhoneInput w-100"
// 														/>
// 													</InputGroup>
// 													{formik.touched.mobileNumber && formik.errors.mobileNumber ? (
// 														<Form.Text className="text-danger">
// 															{formik.errors.mobileNumber}
// 														</Form.Text>
// 													) : null}
// 												</Form.Group>
// 											{/* ) : ( */}
// 												<Form.Group className="">
// 													<InputGroup>
// 														<InputGroup.Text id="basic-addon2">
// 															<img
// 																src={Emailimg}
// 																alt=""
// 																className=""
// 															/>
// 														</InputGroup.Text>
// 														<Form.Control
// 															placeholder="Email ID"
// 															type="email"
// 															value={formik.values.email}
// 															onChange={formik.handleChange}
// 															onBlur={formik.handleBlur}
// 															name="email"
// 														/>
// 													</InputGroup>
// 													{formik.touched.email && formik.errors.email ? (
// 														<Form.Text className="text-danger">
// 															{formik.errors.email}
// 														</Form.Text>
// 													) : null}
// 												</Form.Group>
// 											{/* )} */}
// 										</Col>
// 										<Col xl={6} md={12}>
// 											<Form.Group className="">
// 												<InputGroup>
// 													<InputGroup.Text id="basic-addon2">
// 														<img
// 															src={Cityimg}
// 															alt=""
// 															className=""
// 														/>
// 													</InputGroup.Text>
// 													<Form.Control
// 														placeholder="City"
// 														type="text"
// 														value={formik.values.city}
// 														onChange={formik.handleChange}
// 														onBlur={formik.handleBlur}
// 														name="city"
// 													/>
// 												</InputGroup>
// 												{formik.touched.city &&
// 												formik.errors.city ? (
// 													<Form.Text className="text-danger">
// 														{formik.errors.city}
// 													</Form.Text>
// 												) : null}
// 											</Form.Group>
// 										</Col>
//                                         {/* <Col xl={6} md={12}>
//                                             <Form.Group className="">
//                                                 <InputGroup>
//                                                     <InputGroup.Text id="basic-addon2">
// 														<img
// 															src={Avatarimg}
// 															alt=""
// 															className=""
// 														/>
// 													</InputGroup.Text>
//                                                     <Form.Control
// 														placeholder="Participant Name"
// 														type="text"
// 														value={formik.values.participantName}
// 														onChange={formik.handleChange}
// 														onBlur={formik.handleBlur}
// 														name="participantName"
// 													/>
//                                                 </InputGroup>
//                                                 {formik.touched.participantName && formik.errors.participantName ? (
// 													<Form.Text className="text-danger">
// 														{formik.errors.participantName}
// 													</Form.Text>
// 												) : null}
//                                             </Form.Group>
//                                         </Col> */}
//                                         <Col xl={6} md={12}>
//                                             <Form.Group className="">
//                                                 <InputGroup>
//                                                     <InputGroup.Text id="basic-addon2">
// 														{/* <img
// 															src={Avatarimg}
// 															alt=""
// 															className=""
// 														/> */}
// 														<svg xmlns="http://www.w3.org/2000/svg" width="27" height="24" viewBox="0 0 27 24" fill="none">
// <path d="M1 8.14286L13.5 1L26 8.14286L13.5 15.2857L1 8.14286Z" stroke="#42EFEB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
// <path d="M5.46436 10.8213V17.9641L13.5001 22.4284L21.5358 17.9641V10.8213" stroke="#42EFEB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
// <path d="M26 17.964V8.14258" stroke="#42EFEB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
// <path d="M13.5 15.2861V22.429" stroke="#42EFEB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
// </svg>
// 													</InputGroup.Text>
//                                                     <Form.Control
// 														placeholder="School Name"
// 														type="text"
// 														value={formik.values.schoolName}
// 														onChange={formik.handleChange}
// 														onBlur={formik.handleBlur}
// 														name="schoolName"
// 													/>
//                                                 </InputGroup>
//                                                 {formik.touched.schoolName && formik.errors.schoolName ? (
// 													<Form.Text className="text-danger">
// 														{formik.errors.schoolName}
// 													</Form.Text>
// 												) : null}
//                                             </Form.Group>
//                                         </Col>
//                                         <Col xl={6} md={12}>
//                                             <Form.Group className="">
//                                                 <InputGroup>
//                                                     <InputGroup.Text id="basic-addon2">
// 														<img
// 															src={Cityimg}
// 															alt=""
// 															className=""
// 														/>
// 													</InputGroup.Text>
//                                                     <Form.Control
// 														placeholder="School Address"
// 														type="text"
// 														value={formik.values.schoolAddress}
// 														onChange={formik.handleChange}
// 														onBlur={formik.handleBlur}
// 														name="schoolAddress"
// 													/>
//                                                 </InputGroup>
//                                                 {formik.touched.schoolAddress && formik.errors.schoolAddress ? (
// 													<Form.Text className="text-danger">
// 														{formik.errors.schoolAddress}
// 													</Form.Text>
// 												) : null}
//                                             </Form.Group>
//                                         </Col>
// 										<Col xl={6} md={12}>
//                                             <Form.Group className="">
//                                                 <InputGroup>
//                                                     <InputGroup.Text id="basic-addon2">
// 														<img
// 															src={Avatarimg}
// 															alt=""
// 															className=""
// 														/>
// 													</InputGroup.Text>
//                                                     <Form.Control
// 														placeholder="Parent Name"
// 														type="text"
// 														value={formik.values.parentName}
// 														onChange={formik.handleChange}
// 														onBlur={formik.handleBlur}
// 														name="parentName"
// 													/>
//                                                 </InputGroup>
//                                                 {formik.touched.parentName && formik.errors.parentName ? (
// 													<Form.Text className="text-danger">
// 														{formik.errors.parentName}
// 													</Form.Text>
// 												) : null}
//                                             </Form.Group>
//                                         </Col>
// 										<Col xl={6} md={12}>
// 											<Form.Group className="">
// 												<InputGroup>
// 													<PhoneInput
// 														placeholder="Parent Contact Number"
// 														value={formik.values.parentContactNumber}
// 														onChange={(phone) =>formik.setFieldValue("parentContactNumber",phone)}
// 														onBlur={formik.handleBlur}
// 														name="parentContactNumber"
// 														className="customPhoneInput w-100"
// 													/>
// 												</InputGroup>
// 												{formik.touched.parentContactNumber && formik.errors.parentContactNumber ? (
// 													<Form.Text className="text-danger">
// 														{formik.errors.parentContactNumber}
// 													</Form.Text>
// 												) : null}
// 											</Form.Group>
// 										</Col>
// 										<Col xl={6} md={12}>
// 											<Form.Group className="">
// 												<InputGroup>
// 													<InputGroup.Text id="basic-addon2">
// 														<img
// 															src={Emailimg}
// 															alt=""
// 															className=""
// 														/>
// 													</InputGroup.Text>
// 													<Form.Control
// 														placeholder="Parent Email ID"
// 														type="email"
// 														value={formik.values.parentEmail}
// 														onChange={formik.handleChange}
// 														onBlur={formik.handleBlur}
// 														name="parentEmail"
// 													/>
// 												</InputGroup>
// 												{formik.touched.parentEmail &&
// 												formik.errors.parentEmail ? (
// 													<Form.Text className="text-danger">
// 														{formik.errors.parentEmail}
// 													</Form.Text>
// 												) : null}
// 											</Form.Group>
// 										</Col>
// 										{!profile?.data?.isVerified ?
// 											<Col xl={12} md={12}>
// 												<Form.Group className="d-flex">
// 													<Form.Check type="checkbox" id="termsCondition"

// 														style={{marginTop: "3px"}}
// 													>
// 														<Form.Check.Input type="checkbox"  onChange={(e) => setIsAgreeTerms(e.target.checked)}
// 														checked={isAgreeTerms}/>
// 														<Form.Check.Label></Form.Check.Label>
// 													</Form.Check>
// 													<label style={{color: "#42EFEB"}} >
// 														I have read and accept the
// 														<a href={RulesForQuiz} target="_blank" style={{color: "#42EFEB"}}> rules
// 														</a> for this quiz event
// 													</label>
// 												</Form.Group>
// 											</Col> : null
// 										}
// 										<div>
// 											<Col
// 												xl={6}
// 												md={12}
// 												className="mx-auto"
// 											>
// 												<Button
// 													variant=""
// 													onClick={formik.handleSubmit}
// 													type="button"
// 													disabled={formik.isSubmitting || (!profile?.data?.isVerified && !isAgreeTerms)}
// 													className="next-btn w-100"
// 												>
// 													{formik.isSubmitting && (
// 														<Spinner
// 															size="sm"
// 															variant="ligth"
// 														/>
// 													)}{" "}
// 													Update Profile
// 												</Button>
// 											</Col>
// 										</div>
// 									</Row>
// 								</Form>
// 							</div>
// 						</div>
// 					</div>
// 				</Container>
// 			</div>
// 			{formik.isSubmitting || profile.loading ? <OverlayLoader /> : null}
// 		</>
// 	);
// };

// export default UpdateProfile;

// ############
// above code is commented for temporary purpose and below code is for teacher's registration
// ############

import React, { useEffect, useRef, useState } from "react";
import logo from "../assets/images/logoNew.png";
import TitleSponser from "../assets/images/title-sponsor-logo-v1.png";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import Avatarimg from "../assets/images/Profile.png";
import Emailimg from "../assets/images/Email.png";
import Phoneimg from "../assets/images/Phone.png";
import RulesForQuiz from "../assets/rules-for-quiz.pdf";
import RulesForCSIT from "../assets/BrainPulses - Rules-of-Participaton-1.pdf";
import Cityimg from "../assets/images/City.png";
import AddressImg from "../assets/images/Address.svg";
import CountryImg from "../assets/images/country.svg";
import StateImg from "../assets/images/state.svg";
import Instituteimg from "../assets/images/Institute.png";
import { toast } from "react-hot-toast";
import * as Yup from "yup";
import { useFormik } from "formik";
import { parsePhoneNumber } from "react-phone-number-input";
import AxiosRequest from "../AxiosRequest";
import { useNavigate } from "react-router-dom";
import OverlayLoader from "../components/OverlayLoader";
import uploadImg from "../utils/uploadImg";
import PhoneInput, {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";

const validationSchema = Yup.object({
  fName: Yup.string()
    .trim("")
    .notOneOf([""], "Empty space is not allowed")
    .min(2, "Too Short!")
    .max(25, "Too Long!")
    .required("This field is required.")
    .nullable(),
  lName: Yup.string()
    .trim("")
    .notOneOf([""], "Empty space is not allowed")
    .min(2, "Too Short!")
    .max(25, "Too Long!")
    .required("This field is required.")
    .nullable(),
  email: Yup.string()
    .trim("")
    .notOneOf([""], "Empty space is not allowed")
    .required("This field is required.")
    .email("Email must be in valid format")
    .nullable(),
  gender: Yup.string().required("This field is required.").nullable(),
  city: Yup.string()
    .trim("")
    .notOneOf([""], "Empty space is not allowed")
    .min(2, "Too Short!")
    .max(25, "Too Long!")
    .required("This field is required.")
    .nullable(),
  state: Yup.string()
    .trim("")
    .notOneOf([""], "Empty space is not allowed")
    .min(2, "Too Short!")
    .max(25, "Too Long!")
    .required("This field is required.")
    .nullable(),
  country: Yup.string()
    .trim("")
    .notOneOf([""], "Empty space is not allowed")
    .min(2, "Too Short!")
    .max(25, "Too Long!")
    .required("This field is required.")
    .nullable(),
  mobileNumber: Yup.string()
    .test("valid-phone", "Invalid phone number", (value) => {
      return (
        !value ||
        (isValidPhoneNumber(value || "") && isPossiblePhoneNumber(value || ""))
      );
    })
    .required("This field is required."),
  // participantName: Yup.string()
  // 	.trim("")
  // 	.notOneOf([""], "Empty space is not allowed")
  // 	.min(2, "Too Short!")
  // 	.max(25, "Too Long!")
  // 	.required("This field is required.")
  // 	.nullable(),
  schoolName: Yup.string()
    .trim("")
    .notOneOf([""], "Empty space is not allowed")
    .min(2, "Too Short!")
    .max(60, "Too Long!")
    .required("This field is required.")
    .nullable(),
  schoolAddress: Yup.string()
    .trim("")
    .notOneOf([""], "Empty space is not allowed")
    .min(2, "Too Short!")
    .max(25, "Too Long!")
    .required("This field is required.")
    .nullable(),
});

const initValue = { data: [], loading: false };
const UpdateProfile = () => {
  const reff = useRef();
  const navigate = useNavigate();
  const [profile, setProfile] = useState(initValue);
  const [displayImage, setDisplayImage] = useState(null);
  const [isAgreeTerms, setIsAgreeTerms] = useState(false);

  const formik = useFormik({
    initialValues: {
      // name: "",
      photo: "",
      fName: "",
      lName: "",
      gender: "",
      city: "",
      // ------
      email: "",
      mobileNumber: "",
      // participantName: "",
      schoolName: "",
      schoolAddress: "",
      state: "",
      country: "",
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const payload = {};
        const _name = `${values?.fName.trim()} ${
          values?.lName ? values?.lName.trim() : ""
        }`;
        if (values.photo && values.photo instanceof File) {
          const _img = await uploadImg({ imageFile: values.photo });
          payload["photo"] = _img;
        } else {
          payload["photo"] = values.photo || "";
        }

        payload["name"] = _name.trim();
        payload["gender"] = values.gender;
        payload["city"] = values.city ? values.city.trim() : "";
        payload["state"] = values.state ? values.state.trim() : "";
        payload["country"] = values.country ? values.country.trim() : "";

        if (values.mobileNumber) {
          const parsedNumber = parsePhoneNumber(values.mobileNumber);
          if (parsedNumber) {
            payload["countryCode"] = `+${parsedNumber.countryCallingCode}`;
            payload["mobileNumber"] = parsedNumber.nationalNumber;
          }
        }

        if (values?.email) {
          payload["email"] = values?.email ? values?.email.trim() : "";
        }

        // if(values?.participantName) {
        // 	payload["participantName"] = values?.participantName ? values?.participantName?.trim() : ""
        // }

        if (values?.schoolName) {
          payload["schoolName"] = values?.schoolName
            ? values?.schoolName?.trim()
            : "";
        }

        if (values?.schoolAddress) {
          payload["schoolAddress"] = values?.schoolAddress
            ? values?.schoolAddress?.trim()
            : "";
        }

        const { data } = await AxiosRequest.post(`users/register-user-ccd`, {
          ...payload,
        });

        toast.success(data.message);
        resetForm();
        await getProfile();
        navigate(`/`);
      } catch (err) {
        console.log(err);
        toast.error(
          err?.response?.data?.message || err?.message || "Something went wrong"
        );
      }
    },
  });

  const getProfile = async () => {
    try {
      reff.current = true;
      setProfile((prev) => ({ ...prev, loading: true }));
      const { data } = await AxiosRequest.get(`users/profile/web`);
      const _profleData = {
        ...data?.data?.data,
        quizStatistics: data?.data?.quizStatistics,
      };
      setProfile((prev) => ({
        data: _profleData,
        loading: false,
      }));
      localStorage.setItem("user", JSON.stringify(_profleData));
    } catch (err) {
      console.log(err);
      toast.error(
        err?.response?.data?.message || err?.message || "Something went wrong"
      );
      setProfile(initValue);
    }
  };

  useEffect(() => {
    if (!reff?.current) getProfile();
  }, []);

  const setProfileData = async () => {
    await formik.setFieldValue("photo", profile?.data?.photo || "");
    await formik.setFieldValue(
      "fName",
      (profile?.data?.name && profile?.data?.name.split(" ")[0]) || ""
    );
    await formik.setFieldValue(
      "lName",
      (profile?.data?.name && profile?.data?.name.split(" ")[1]) || ""
    );

    if (profile?.data?.countryCode && profile?.data?.mobileNumber) {
      await formik.setFieldValue(
        "mobileNumber",
        `${profile?.data?.countryCode}${profile?.data?.mobileNumber}`
      );
    } else {
      await formik.setFieldValue("mobileNumber", "");
    }
    // --- optional ---
    await formik.setFieldValue("email", profile?.data?.email || "");
    await formik.setFieldValue("gender", profile?.data?.gender || "");
    await formik.setFieldValue("city", profile?.data?.city || "");
    await formik.setFieldValue("state", profile?.data?.state || "");
    await formik.setFieldValue("country", profile?.data?.country || "");

    // --- Extra Fields ---
    // await formik.setFieldValue("participantName", profile?.data?.participantName || "");
    await formik.setFieldValue("schoolName", profile?.data?.schoolName || "");
    await formik.setFieldValue(
      "schoolAddress",
      profile?.data?.schoolAddress || ""
    );
    // await formik.setFieldValue("parentName", profile?.data?.parentName || "");
    // await formik.setFieldValue(
    //   "parentContactNumber",
    //   profile?.data?.parentContactNumber || ""
    // );
    // await formik.setFieldValue("parentEmail", profile?.data?.parentEmail || "");
  };

  useEffect(() => {
    if (!profile.loading && profile.data) setProfileData();
  }, [profile]);

  const handleSelectImage = (event) => {
    const _file = event.target.files && event.target.files[0];
    formik.setFieldValue("photo", _file);
    setDisplayImage(window.URL.createObjectURL(_file));
  };

  console.log("formik vlaues", formik.values);
  return (
    <>
      <div>
        <Container>
          <div className=" login-page profile-page">
            <div className="login-logo header1">
              <img src={logo} alt="" className="logo logo1" />
              {/* <span className="vertical-line"></span>
              <img src={TitleSponser} alt="" className="logo logo2" /> */}
            </div>
            <div className="profile-box login-box">
              <h4 className="fontwhite24 text-center my-5">Complete Profile</h4>
              <div
                id="content custom-uploader"
                className=" d-flex justify-content-center"
              >
                <label htmlFor="profileImg" className="uploader">
                  <input
                    hidden
                    id="profileImg"
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    onChange={handleSelectImage}
                  />
                  {formik.values.photo ? (
                    <img
                      src={displayImage || formik.values.photo}
                      alt="profile"
                    />
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="23"
                      viewBox="0 0 30 23"
                      fill="none"
                    >
                      <path
                        d="M16.0726 22.7273V16.2338H20.3586L15.0011 9.74026L9.64355 16.2338H13.9296V22.7273H8.57204V22.6732C8.39203 22.684 8.22059 22.7273 8.03629 22.7273C5.90493 22.7273 3.86087 21.8721 2.35377 20.3499C0.846678 18.8277 0 16.7631 0 14.6104C0 10.4459 3.11808 7.05195 7.11908 6.58658C7.46987 4.7344 8.4487 3.06357 9.88722 1.86147C11.3257 0.659371 13.134 0.00114289 15.0011 0C16.8684 0.00105555 18.677 0.659205 20.1158 1.86128C21.5547 3.06335 22.534 4.73422 22.8852 6.58658C26.8862 7.05195 30 10.4459 30 14.6104C30 16.7631 29.1533 18.8277 27.6462 20.3499C26.1391 21.8721 24.0951 22.7273 21.9637 22.7273C21.7837 22.7273 21.6101 22.684 21.428 22.6732V22.7273H16.0726Z"
                        fill="#42EFEB"
                      />
                    </svg>
                  )}
                </label>
              </div>
              <div className="right-section">
                <Form className="login-form w-100">
                  <Row className="p-md-5 p-3 g-4">
                    <Col xl={6} md={12}>
                      <Form.Group className="">
                        <InputGroup>
                          <InputGroup.Text id="basic-addon2">
                            <img src={Avatarimg} alt="" className="" />
                          </InputGroup.Text>
                          <Form.Control
                            placeholder="First Name"
                            type="text"
                            value={formik.values.fName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name="fName"
                          />
                        </InputGroup>
                        {formik.touched.fName && formik.errors.fName ? (
                          <Form.Text className="text-danger">
                            {formik.errors.fName}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xl={6} md={12}>
                      <Form.Group className="">
                        <InputGroup>
                          <InputGroup.Text id="basic-addon2">
                            <img src={Avatarimg} alt="" className="" />
                          </InputGroup.Text>
                          <Form.Control
                            placeholder="Last Name"
                            type="text"
                            value={formik.values.lName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name="lName"
                          />
                        </InputGroup>
                        {formik.touched.lName && formik.errors.lName ? (
                          <Form.Text className="text-danger">
                            {formik.errors.lName}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xl={12}>
                      <Form.Group className="">
                        <div className="d-flex align-items-center flex-wrap">
                          <span className="fontwhite14 me-2">Gender :</span>
                          <label htmlFor="male" class="size-2 radioButton">
                            <input
                              type="radio"
                              id="male"
                              name="gender"
                              className="radioButtonInput"
                              checked={formik.values.gender === "male"}
                              onChange={() =>
                                formik.setFieldValue("gender", "male")
                              }
                              onBlur={formik.handleBlur}
                            />
                            Male
                          </label>
                          <label htmlFor="female" class="size-2 radioButton">
                            <input
                              type="radio"
                              id="female"
                              name="gender"
                              className="radioButtonInput"
                              checked={formik.values.gender === "female"}
                              onChange={() =>
                                formik.setFieldValue("gender", "female")
                              }
                              onBlur={formik.handleBlur}
                            />
                            Female
                          </label>
                        </div>
                        {formik.touched.gender && formik.errors.gender ? (
                          <Form.Text className="text-danger">
                            {formik.errors.gender}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xl={6} md={12}>
                      {/* {profile?.data?.emailLogin ? ( */}
                      {/* <Form.Group className="mb-0 mb-lg-4"> */}
					  <Form.Group className="mb-0">

                        <InputGroup>
                          <PhoneInput
                            placeholder="Enter whatsapp number"
                            value={formik.values.mobileNumber}
                            onChange={(phone) =>
                              formik.setFieldValue("mobileNumber", phone)
                            }
                            onBlur={formik.handleBlur}
                            name="mobileNumber"
                            className="customPhoneInput w-100"
                          />
                        </InputGroup>
                        {formik.touched.mobileNumber &&
                        formik.errors.mobileNumber ? (
                          <Form.Text className="text-danger">
                            {formik.errors.mobileNumber}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                        <small className="text-white fw-light">This number will be used to send login details
                        </small>
                    </Col>
                    <Col xl={6} md={12}>
                      <Form.Group className="">
                        <InputGroup>
                          <InputGroup.Text id="basic-addon2">
                            <img src={Emailimg} alt="" className="" />
                          </InputGroup.Text>
                          <Form.Control
                            placeholder="Email ID"
                            type="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name="email"
                          />
                        </InputGroup>
                        {formik.touched.email && formik.errors.email ? (
                          <Form.Text className="text-danger">
                            {formik.errors.email}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                        <small className="text-white fw-light">
                          This email ID will be used to send login details
                        </small>
                    </Col>

                    <Col xl={6} md={12}>
                      <Form.Group className="">
                        <InputGroup>
                          <InputGroup.Text id="basic-addon2">
                            {/* <img
															src={Avatarimg}
															alt=""
															className=""
														/> */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="27"
                              height="24"
                              viewBox="0 0 27 24"
                              fill="none"
                            >
                              <path
                                d="M1 8.14286L13.5 1L26 8.14286L13.5 15.2857L1 8.14286Z"
                                stroke="#42EFEB"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M5.46436 10.8213V17.9641L13.5001 22.4284L21.5358 17.9641V10.8213"
                                stroke="#42EFEB"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M26 17.964V8.14258"
                                stroke="#42EFEB"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M13.5 15.2861V22.429"
                                stroke="#42EFEB"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </InputGroup.Text>
                          <Form.Control
                            placeholder="School Name"
                            type="text"
                            value={formik.values.schoolName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name="schoolName"
                          />
                        </InputGroup>
                        {formik.touched.schoolName &&
                        formik.errors.schoolName ? (
                          <Form.Text className="text-danger">
                            {formik.errors.schoolName}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>

                    <Col xl={6} md={12}>
                      <Form.Group className="">
                        <InputGroup>
                          <InputGroup.Text id="basic-addon2">
                            <img src={AddressImg} alt="" className="" />
                          </InputGroup.Text>
                          <Form.Control
                            placeholder="School Address"
                            type="text"
                            value={formik.values.schoolAddress}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name="schoolAddress"
                          />
                        </InputGroup>
                        {formik.touched.schoolAddress &&
                        formik.errors.schoolAddress ? (
                          <Form.Text className="text-danger">
                            {formik.errors.schoolAddress}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xl={6} md={12}>
                      <Form.Group className="">
                        <InputGroup>
                          <InputGroup.Text id="basic-addon2">
                            <img src={Cityimg} alt="" className="" />
                          </InputGroup.Text>
                          <Form.Control
                            placeholder="City"
                            type="text"
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name="city"
                          />
                        </InputGroup>
                        {formik.touched.city && formik.errors.city ? (
                          <Form.Text className="text-danger">
                            {formik.errors.city}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>

                    <Col xl={6} md={12}>
                      <Form.Group className="">
                        <InputGroup>
                          <InputGroup.Text id="basic-addon2">
                            <img src={StateImg} alt="" className="" />
                          </InputGroup.Text>
                          <Form.Control
                            placeholder="State"
                            type="text"
                            value={formik.values.state}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name="state"
                          />
                        </InputGroup>
                        {formik.touched.city && formik.errors.state ? (
                          <Form.Text className="text-danger">
                            {formik.errors.state}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xl={6} md={12}>
                      <Form.Group className="">
                        <InputGroup>
                          <InputGroup.Text id="basic-addon2">
                            <img src={CountryImg} alt="" className="" />
                          </InputGroup.Text>
                          <Form.Control
                            placeholder="Country"
                            type="text"
                            value={formik.values.country}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name="country"
                          />
                        </InputGroup>
                        {formik.touched.country && formik.errors.country ? (
                          <Form.Text className="text-danger">
                            {formik.errors.country}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    {!profile?.data?.isVerified ? (
                      <Col xl={12} md={12}>
                        <Form.Group className="d-flex">
                          <Form.Check
                            type="checkbox"
                            id="termsCondition"
                            style={{ marginTop: "3px" }}
                          >
                            <Form.Check.Input
                              type="checkbox"
                              onChange={(e) =>
                                setIsAgreeTerms(e.target.checked)
                              }
                              checked={isAgreeTerms}
                            />
                            <Form.Check.Label></Form.Check.Label>
                          </Form.Check>
                          <label style={{ color: "#42EFEB" }}>
                            I have read and accept the
                            <a
                              href={RulesForCSIT}
                              target="_blank"
                              style={{ color: "#42EFEB" }}
                            >
                              {" "}
                              rules
                            </a>{" "}
                            for this quiz event
                          </label>
                        </Form.Group>
                      </Col>
                    ) : null}
                    <div>
                      <Col xl={6} md={12} className="mx-auto">
                        <Button
                          variant=""
                          onClick={formik.handleSubmit}
                          type="button"
                          disabled={
                            formik.isSubmitting ||
                            (!profile?.data?.isVerified && !isAgreeTerms)
                          }
                          className="next-btn w-100"
                        >
                          {formik.isSubmitting && (
                            <Spinner size="sm" variant="ligth" />
                          )}{" "}
                          Update Profile
                        </Button>
                      </Col>
                    </div>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {formik.isSubmitting || profile.loading ? <OverlayLoader /> : null}
    </>
  );
};

export default UpdateProfile;
